import React, { useContext } from 'react';
import context from '../../context';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Section } from '../../styled-components';

const SectionCustom = styled(Section)`

`
const Image = styled.img`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  object-position: center;
  @media(min-width: 768px){
    height: 100%;
  }
`
const MosaicContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); /* Define las columnas del grid */
  gap: 10px; /* Espacio entre las imágenes */
`;
const Image1 = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  buttom: 10px;
  top:1;
  border: 2px solid black;
  
  object-position: center;
 
`
const InfoCont = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Title = styled.h2`
  //width: 50%;
  color: ${props => props.theme.primaryColor};
  margin: 2rem 0;
`
const Description = styled.div`

`

export default ()=> {
  const state = useContext(context);
  return(
    <SectionCustom>
      <Container>
        <Row>
          <Col xs={12} md={{ span: 7, order: 2 }}>
            <Image alt="a" src={state.about.history.background} />
          </Col>
          <Col xs={12} md={{ span: 5, order: 1 }}>
            <InfoCont>
            

            <Title>
              {state.about.history.title}
            </Title>
            <Description dangerouslySetInnerHTML={{ __html: state.about.history.description }} />

           
            </InfoCont>
          </Col>          
        </Row> 
        <br/>
        <Title>¡Nuestra meta es tu felicidad!</Title>
        <MosaicContainer>
            <Image1 alt="a" src={state.about.history.image1} />
             <br/>
            <Image1 alt="a" src={state.about.history.image2} />
            <br/>
            <Image1 alt="a" src={state.about.history.image3} />
            <br/>
            <Image1 alt="a" src={state.about.history.image4} />
            <br/>
            <Image1 alt="a" src={state.about.history.image5} />
            <br/>
            <Image1 alt="a" src={state.about.history.image6} />
            <br/>
            <Image1 alt="a" src={state.about.history.image7} />
            <br/>
            <Image1 alt="a" src={state.about.history.image8} />
             <br/>
            <Image1 alt="a" src={state.about.history.image9} />
            <br/>
            <Image1 alt="a" src={state.about.history.image10} />
            <br/>
            <Image1 alt="a" src={state.about.history.image11} />
            <br/>
            <Image1 alt="a" src={state.about.history.image12} />
            <br/>
            <Image1 alt="a" src={state.about.history.image13} />
            <br/>
            </MosaicContainer>     
      </Container>
    </SectionCustom>
  )
}